<template>
  <vx-card :title="this.pageTitle" :key="componentKey">
    <template slot="actions">
      <div>
        <vs-button
          v-if="$can('create', 'city')"
          size="small" color="success" type="border" icon-pack="feather" icon="icon-map-pin"
          @click="createEntry()" ref="createEntryBtn"
        >
          Agregar Ciudad
        </vs-button>
      </div>
    </template>

    <div class="vx-row">
      <div class="vx-col md:w-1/4 w-full mt-10">
        <div class="m-3 mt-10">
          <filter-groups :model="queryStrings" :filter-groups="filterGroups" @update="getEntries"/>
          <vs-divider/>
          <div>
            <ul class="centerx">
              <div class="mt-4"><b>Papelera de reciclaje</b></div>
              <li>
                <vs-checkbox v-model="recycleBin" class="mt-4" @change="getEntries">Si</vs-checkbox>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="vx-col md:w-3/4 w-full">
        <vs-table max-items="10" pagination search stripe :data="entries" :hoverFlat="true">
          <template slot="thead">
            <vs-th sort-key="state_id">Departamento</vs-th>
            <vs-th sort-key="name">Nombre</vs-th>
            <vs-th>Acciones</vs-th>
          </template>

          <template slot-scope="{data}">
            <tbody>
            <vs-tr :data="record" :key="index" v-for="(record, index) in data">
              <vs-td>
                <p class="font-medium">{{ record.state.name }}</p>
              </vs-td>
              <vs-td>
                <p class="font-medium">{{ record.name }}</p>
              </vs-td>
              <vs-td>
                <div class="flex" v-if="!recycleBin">
                  <vx-tooltip text="Editar">
                    <vs-button
                      :disabled="!($can('retrieve', 'city') || $can('update', 'city'))"
                      radius size="large" color="success" type="flat" icon-pack="feather" icon="icon-edit"
                      @click="updateEntry(record)" @click.stop
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Publicar">
                    <vs-button
                      radius size="large" :color="record.in_production ? 'dark' : 'success'" type="flat" icon-pack="feather" icon="icon-send"
                      :disabled="record.in_production"
                      @click="confirmCommit(record)" @click.stop
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Eliminar">
                    <vs-button
                      :disabled="!$can('delete', 'city')"
                      radius size="large" color="warning" type="flat" icon-pack="feather" icon="icon-trash-2"
                      @click="confirmDelete(record)" @click.stop
                    />
                  </vx-tooltip>
                </div>
                <div class="flex" v-else>
                  <vx-tooltip text="Restaurar">
                    <vs-button
                        :disabled="!($can('create', 'product') || $can('update', 'product'))"
                        radius size="large" color="success" type="flat" icon-pack="feather" icon="icon-refresh-cw"
                        @click="confirmRestore(record)"
                    />
                  </vx-tooltip>
                </div>
              </vs-td>
            </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>

      <vs-popup :title="popupTitle" :active.sync="popupActive" button-close-hidden>
        <template>
          <div class="vx-row">
            <div class="vx-col w-full">
              <label for="state" class="w-full select-large">Departamento</label>
              <v-select
                id="state"
                name="state"
                label="name"
                index="id"
                :options="states"
                :placeholder="''"
                :searchable="true"
                v-model="entryModel.state"
                class="w-full"
              />
              <br/>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col w-full">
              <label for="name" class="w-full select-large">Nombre <i>(requerido)</i></label>
              <vs-input id="name" name="name" v-model="entryModel.name" class="w-full"/>
              <br/>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="flex flex-wrap justify-between">
                <vs-row vs-type="flex" vs-justify="flex-end">
                  <vs-button size="small" color="danger" type="border" class="mr-3 mb-2" @click="popupDismiss">
                    Cancelar
                  </vs-button>
                  <vs-button size="small" class="mb-2" @click="createOrUpdateEntry()" :disabled="!validateForm">
                    Guardar
                  </vs-button>
                </vs-row>
              </div>
            </div>
          </div>
        </template>
      </vs-popup>
    </div>
  </vx-card>
</template>

<script>
  import CoreClient from "../../utils/coreClient";
  import EntriesClient from "../../utils/entriesClient";
  import Notifier from "./../../utils/notification";
  import FilterGroups from '@/components/filter-groups/FilterGroups';
  import vSelect from "vue-select";
  import * as notificationMixins from "./../../utils/notificationMixins.js";

  let notifier = new Notifier();
  const ENTRIES_CLIENT = new EntriesClient("cities");
  const STATES_CLIENT = new CoreClient("states");

  export default {
    data() {
      return {
        componentKey: 0,
        pageTitle: "Ciudades",
        popupActive: false,
        popupTitle: "",
        entries: [],
        states: [],
        queryStrings: {},
        filterGroups: [
          {
            name: "state_id",
            label: "Departamento",
            filters: [],
          },
        ],
        recycleBin: false,
        entryModel: {
          name: "",
          state: null,
        }
      };
    },

    mounted() {
      this.init();
    },

    computed: {
      validateForm() {
        return this.entryModel.name !== ''
          && this.entryModel.name !== null
          && this.entryModel.state !== null;
      }
    },

    methods: {
      async init() {
        this.$vs.loading();
        await this.getEntries(false)
          .then(() => {
            this.getStates()
              .then(() => {
                this.$vs.loading.close();
              });
          });
      },

      async getEntries(showLoading = true) {
        if (showLoading) this.$vs.loading();
        await ENTRIES_CLIENT.all({fromRecycleBin: this.recycleBin, queryStrings: this.queryStrings})
          .then(response => {
            this.entries = response.data;
            if (showLoading) this.$vs.loading.close();
          })
          .catch(error => {
            if (showLoading) this.$vs.loading.close();
            notifier.notification("error");
          });
      },

      async getStates(showLoading = true) {
        if (showLoading) this.$vs.loading();
        await STATES_CLIENT.all({queryStrings: {representation_type: "simple"}})
          .then(response => {
            this.states = response.data;
            let stateFilters = [];
            response.data.forEach(element => {
              stateFilters.push({value: element.id, label: element.name});
            });
            this.filterGroups[0].filters = stateFilters;
            if (showLoading) this.$vs.loading.close();
          })
          .catch(error => {
            if (showLoading) this.$vs.loading.close();
            notifier.notification("error");
          });
      },

      async deleteEntry(entryId) {
        if (entryId !== undefined && entryId !== null && entryId !== "") {
          this.$vs.loading();
          await ENTRIES_CLIENT.delete({pathParameters: {entryId: entryId}})
            .then(response => {
              notifier.notification("deleted");
            })
            .then(() => {
              this.componentKey += 1;
              this.init();
            })
            .catch(error => {
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });
        }
      },

      confirmDelete(entry) {
        notificationMixins.DeleteEntryNotification.fire().then(result => {
          if (result.value) {
            this.deleteEntry(entry.id);
          }else{
            this.componentKey += 1;
          }
        });
      },

      confirmCommit(entry) {
        notificationMixins.CommitEntryNotification.fire()
          .then(result => {
            if (result.value) {
              if (entry.id !== null && entry.id !== undefined && entry.id !== "") {
                this.$vs.loading();
                ENTRIES_CLIENT.commit({pathParameters: {entryId: entry.id}})
                  .then(response => {
                    notifier.notification("committed");
                    this.getEntries();
                  })
                  .catch(error => {
                    notifier.alertMessage("error");
                  });
                this.$vs.loading.close();
              }
            }
          });
      },

      confirmRestore(entry) {
        notificationMixins.RestoreEntryNotification.fire()
            .then(result => {
              if (result.value) {
                if (entry.id !== null && entry.id !== undefined && entry.id !== "") {
                  this.$vs.loading();
                  ENTRIES_CLIENT.restore({pathParameters: {entryId: entry.id}})
                      .then(response => {
                        notifier.notification("restored");
                        this.getEntries();
                      })
                      .catch(error => {
                        notifier.alertMessage("error");
                      });
                  this.$vs.loading.close();
                }
              }
            });
      },

      createEntry() {
        this.entryModel = {
          name: "",
          state: null,
        };
        this.popupTitle = "Nueva Ciudad";
        this.popupActive = true;
      },

      updateEntry(entry) {
        this.entryModel = {
          id: entry.id,
          name: entry.name,
          state: entry.state,
        };
        this.popupTitle = "Editar Ciudad";
        this.popupActive = true;
      },

      async createOrUpdateEntry() {
        this.$vs.loading();
        this.cleanModel();
        if(this.entryModel.id !== null && this.entryModel.id !== undefined && this.entryModel.id !== "") {
          await ENTRIES_CLIENT.update({pathParameters: {entryId: this.entryModel.id}, data: this.entryModel})
            .then(response => {
              notifier.notification("updated");
              this.popupDismiss();
            })
            .then(() => {
              this.init();
            })
            .catch(error => {
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });
        }else{
          await ENTRIES_CLIENT.create({data: this.entryModel})
            .then(response => {
              notifier.notification("created");
              this.popupDismiss();
            })
            .then(() => {
              this.init();
            })
            .catch(error => {
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });
        }
      },

      popupDismiss() {
        this.popupActive = false;
        this.entryModel = {
          name: "",
          state: null,
        };
      },

      cleanModel() {
        Object.keys(this.entryModel).forEach(key => {
          if (this.entryModel[key] === null || this.entryModel[key] === "") {
            delete this.entryModel[key];
          }
        })
      },
    },

    components: {
      'filter-groups': FilterGroups,
      "v-select": vSelect
    }
  };
</script>

<style scoped>
  .vs-table--tbody-table .tr-values.selected {
    cursor: default;
  }
</style>
